import { MaterialModule } from './../material-module';
import { FloorplanSpecsBoxComponent } from './floorplan-specs-box/floorplan-specs-box.component';
import { FloorplanBoxComponent } from './floorplan-box/floorplan-box.component';
import { RouterModule } from '@angular/router';
import { StillHaveQuestionsComponent } from './../components/homepage2/still-have-questions/still-have-questions.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//developer notes - 3 steps
//need to delcare component as public by adding to exports array as well as to declarations
//e.g. FloorplanBoxComponent etc.
//and then in the module which uses these shared components, add to the imports array

@NgModule({
  declarations: [
    StillHaveQuestionsComponent,
    FloorplanBoxComponent,
    FloorplanSpecsBoxComponent,
  ],
  exports: [StillHaveQuestionsComponent, FloorplanBoxComponent, FloorplanSpecsBoxComponent],
  imports: [CommonModule, RouterModule, MaterialModule],
})
export class SharedModule {}
