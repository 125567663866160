import { Component, OnInit, Input } from '@angular/core';
import { Floorplan } from '../../models/floorplan';

@Component({
  selector: 'app-floorplan-specs-box',
  templateUrl: './floorplan-specs-box.component.html',
  styleUrls: ['./floorplan-specs-box.component.scss'],
})
export class FloorplanSpecsBoxComponent implements OnInit {
  @Input() floorplan!: Floorplan;

  constructor() {}

  ngOnInit(): void {}
}
