<div class="still_have_more_questions_row">
  <div class="still_have_more">
    <div class="line1">
      Still have questions or want more information on pricing and financing?
    </div>
    <div class="line2">Call us or drop by, we’re open 7 days a week!</div>
    <div class="location_hours">
      <div class="address">
        <div class="address_title">Address:</div>
        <p>10910 Evergreen Way</p>
        <p>Everett, WA 98204</p>
        <p><a href="tel:4253535464">425-353-5464</a>
        </p>
      </div>
      <div class="sales_hours">
        <div class="sales_hours_title">Hours:</div>
        <p>Everyday: 9AM - 5PM</p>
        <br>
        <p>2024 Holiday Closures</p>
        <p>Nov 28: Thanksgiving Closed</p>
        <p>Dec 25th: Christmas Closed</p>
        <p>Dec 31st: New Years Eve Closed</p>
        <p>Jan 1st: New Years Closed</p>
      </div>
    </div>
  </div>

  <div class="google_map">
    <a href="https://goo.gl/maps/MqyDVnqBxoQaQRtTA" target="_blank">
      <img
        src="../../../../assets/google-map.png"
        alt="heritage home center location on google map"
      />
    </a>
  </div>
</div>
