import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-still-have-questions',
  templateUrl: './still-have-questions.component.html',
  styleUrls: ['./still-have-questions.component.scss'],
})
export class StillHaveQuestionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
